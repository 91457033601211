import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import image404 from "../images/404_undraw.png";
const Page404 = styled.div`
  margin: 0 auto;
  max-width: 835px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 100px;
`;

const Image404 = styled.img`
  height: 50%;
  width: 70%;
`;

const Text404 = styled.div``;

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <Page404>
        <div className="image-404">
          <Image404 src={image404} />
        </div>
        <Text404 className="text-404">
          <h1>Page not Found.</h1>
          <p>Sorry, but the page you were looking for could not be found.</p>
        </Text404>
      </Page404>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
